import React, { useState, useEffect } from 'react'
import "./CountryFlags.css";
import Card from "../components/card";
import axios from 'axios';
import { Buffer } from "buffer";
import { Link, Route, Routes } from "react-router-dom";
import { encrypt , decrypt } from '../crypto';
function global({
    search,
    isLoading
}) {
    // console.log(search);  
    // useEffect(() => {
    //     console.log(isLoading);
    // }, [isLoading]);
    const [searching, setsearching] = useState(false)
    const [iso2code, setiso2code] = useState([]);
    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const username = process.env.REACT_APP_USERNAME_ESIM;
    const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();
    //   const dataBody = JSON.stringify({
    //     username,
    //     password
    // });
    const dataBody = encrypt({
        username,
        password
    });
    useEffect(() => {
        const fetchGlobal = async () => {
            setIsPending(true);
            const conf = {
                method: 'post',
                url: apiUrl + 'Global',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    token: dataBody
                }
            }
            try {
                const result = await axios(conf);
                const encryptedResponse = await result.data
                const decryptedData = decrypt(encryptedResponse);
                setData(decryptedData);
                setIsPending(false);
            } catch (error) {
                console.error(`Error occurred while fetching data: ${error}`);
                setIsPending(false);
            }
        };
        if (!search || search.length <= 0) fetchGlobal()
    }, []);
    useEffect(() => {
        if (search && search.length > 0) {
            setsearching(true);
            // setTimeout(() => {

            // }, 500);
            let commonBundles = [];
            let iso2Codes = [];
            // console.log(search);
            search.forEach((item, index) => {

                iso2Codes.push(item.iso2_code.toLowerCase());
                if (index === 0) {
                    commonBundles = [...item.bundles];
                } else {
                    commonBundles = commonBundles.filter(bundle =>
                        item.bundles.some(b => b.bundle_code === bundle.bundle_code)
                    );
                }
            });
            // console.log(commonBundles);
            setiso2code(iso2Codes);
            setData(commonBundles);
            // setIsPending(false);
            // setIsPending(true);
        }
    }, [search]);

    function getUnique(arr, comp) {
        const unique = arr
            .map(e => e[comp])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e])
            .map(e => arr[e]);

        return unique;
    }
    // console.log (
    // data ,
    //  isLoading , isPending 
    //  , searching
        // , search , iso2code
        // );
    return (
        <>
            {isLoading && <div className="country-flags1" style={{ height: "200px", alignItems: "center" }}><div class="loader"></div></div>}
            {isPending && <div className="country-flags1" style={{ height: "200px", alignItems: "center" }}><div class="loader"></div></div>}
            {data && <section className="separator-parent">
                <div className="framearrowrightfffsvg-frame" style={{
                    justifyContent: "center",
                }}>
                    {!isPending && !searching && data && data.map((bundle, index) => {
                        // if (bundle.bundle_category !== "global" ) {

                        return (
                            <Card
                                index={index}
                                trsvg={'/Global.svg'}
                                title={bundle.bundle_marketing_name}
                                price={bundle.subscriber_price}
                                validity={bundle.validity}
                                category={bundle.bundle_category}
                                region_name={bundle.region_name}
                                country_code={bundle.country_code}
                                gprs_limit={bundle.gprs_limit}
                                data_unit={bundle.data_unit}
                                country_iso2_code={bundle.country_iso2_code}
                                isCountry={false}
                                searching={searching}
                                bundle={bundle}
                            />
                        )
                    })}

                    {!isLoading && searching && data && getUnique(data, 'gprs_limit').map((bundle, index) => {
                        // if (bundle.bundle_category !== "global" ) {

                        return (
                            <Card
                                key={index}
                                index={index}
                                trsvg={(search && search.length > 0) ? iso2code : '/Global.svg'}
                                title={bundle.bundle_marketing_name}
                                price={bundle.subscriber_price}
                                validity={bundle.validity}
                                category={bundle.bundle_category}
                                region_name={bundle.region_name}
                                country_code={bundle.country_code}
                                gprs_limit={bundle.gprs_limit}
                                data_unit={bundle.data_unit}
                                country_iso2_code={bundle.country_iso2_code}
                                isCountry={(search && search.length > 0) ? true : false}
                                searching={searching}
                                bundle={bundle}
                            />
                        )
                        // }
                    }
                    )}

                    {( !isPending) && data.length === 0 && <div className="no-results" style={{ height: "200px" }}>
                        <p
                            style={{
                                fontSize: "inherit",
                                lineHeight: "60px",
                                fontWeight: "500",
                                color: "#ca3770",
                            }}
                        >No bundles are available</p>
                    </div>}


                    <img
                        className="whatsapp-logo6d974d7esvg-icon6"
                        loading="eager"
                        alt=""
                        src="/whatsapplogo6d974d7esvg4@2x.png"
                        onClick={() => {
                            window.open("https://api.whatsapp.com/send/?phone=447365833569")
                        }}
                    />
                    <Link to="/trend-AI">
                        <img
                            className="TrendAI-Logo"
                            alt=""
                            src="/chatbot.png"
                            title="Contact Our AI"
                        />
                    </Link>
                </div>
            </section>}
        </>
    )
}


export default global;