import Divcard from "./Divcard";
import "./CountryFlags.css";
import { useState, useEffect } from "react";
import Collapsible from "./collapse";
import axios from 'axios';
import {Link } from "react-router-dom";
import { Buffer } from "buffer";
import { encrypt , decrypt } from '../crypto';
const RegionFlags = ({
  isCountry
}) => {

  const [expandedIndex, setexpandedIndex] = useState(null)
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const username = process.env.REACT_APP_USERNAME_ESIM;
  const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();
//   const dataBody = JSON.stringify({
//     username,
//     password
// });
const dataBody = encrypt({
  username,
  password
});
  useEffect(() => {

    const fetchRegions = async () => {
      const conf = {
        method: 'post',
        url: apiUrl + 'AllRegions',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
            token: dataBody
        }
      }
      try {
        const result = await axios(conf);
        const encryptedResponse = await result.data
        const decryptedData = decrypt(encryptedResponse);
        setData(decryptedData);
        setIsPending(false);
      } catch (error) {
        console.error(`Error occurred while fetching data: ${error}`);
        setIsPending(false);
      }
    };
    fetchRegions()
  }, []);

  return (
    <>
      {isPending && <div className="country-flags1" style={{ height: "200px", alignItems: "center" }}><div class="loader"></div></div>}
      {!isPending && data && <section className="country-flags1">
        <div className="div-row">
          <div className="divrow16">

            {data && data.map((item, index) => {
              return (
                <Collapsible
                  index={index}
                  // trsvg={`/${item.image}`}
                  trsvg={`/${item.name.replace(" ", "-")}.svg`}
                  turkey={item.name}
                  bundles={item.bundles}
                  isCountry={false}
                  searching={false}
                  expandedIndex={expandedIndex}
                  setExpandedIndex={setexpandedIndex}
                />
              );
            })}

            <img
              className="whatsapp-logo6d974d7esvg-icon6"
              loading="eager"
              alt=""
              src="/whatsapplogo6d974d7esvg4@2x.png"
              onClick={() => {
                window.open("https://api.whatsapp.com/send/?phone=447365833569")
              }}
            />
            <Link to="/trend-AI">
                <img
                  className="TrendAI-Logo"
                  alt=""
                  src="/chatbot.png"
                  title="Contact Our AI"
                />
              </Link>
          </div>

        </div>
      </section>}
    </>

  );
};

export default RegionFlags;
