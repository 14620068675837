import "./TrendAI.css";
import Navigation1 from "../components/Navigation1";
import Divfooter1 from "../components/Divfooter1";
import {
    FaSquareFacebook,
    FaSquareInstagram,
    FaSquareYoutube,
    FaTiktok,
    FaSquarePinterest
} from "react-icons/fa6";
import { FaArrowCircleUp } from "react-icons/fa";
import { useEffect, Suspense, useState, useRef } from "react";
import { Canvas, useFrame } from '@react-three/fiber';
import { MTLLoader, OBJLoader } from 'three-stdlib';
import { OrbitControls, Html, Loader } from '@react-three/drei';
import * as THREE from 'three';
import { TypeAnimation } from 'react-type-animation';
import { Buffer } from "buffer";
import axios from 'axios';
import { Link } from "react-router-dom";
import { encrypt, decrypt } from '../crypto';



function Model({ objUrl, mtlUrl }) {
    const [obj, setObj] = useState();
    const [mtl, setMtl] = useState();
    const mesh = useRef(); // Create a reference to store the mesh

    useEffect(() => {
        const objLoader = new OBJLoader();
        const mtlLoader = new MTLLoader();

        objLoader.load(objUrl, setObj, undefined, (error) => console.error('An error occurred while loading the OBJ', error));
        mtlLoader.load(mtlUrl, setMtl, undefined, (error) => console.error('An error occurred while loading the MTL', error));
    }, [objUrl, mtlUrl]);

    useEffect(() => {
        if (obj && mtl) {
            mtl.preload();
            if (obj.traverse) {
                obj.traverse((child) => {
                    if (child.isMesh) {
                        child.material = new THREE.MeshBasicMaterial({ color: 0xca3770, side: THREE.DoubleSide }); // Use a red material
                    }
                });
            }
        }
    }, [obj, mtl]);
    useFrame(({ clock }) => {
        if (mesh.current) {
            mesh.current.rotation.y = clock.getElapsedTime();
        }
    });
    return obj ? <primitive object={obj} ref={mesh} /> : null;
}
function TrendAi() {
    useEffect(() => {
        function handleScroll() {
            const element = document.querySelector(".whatsapp-logo6d974d7esvg-icon");
            const { scrollHeight, scrollTop, clientHeight } = document.documentElement;

            if (element) {
                if (scrollHeight - scrollTop === clientHeight) {
                    // Scroll has reached the end
                    // console.log("Scroll reached the end");
                    element.style.bottom = "75%";
                } else {
                    element.style.bottom = "-1%";
                }
            }
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    const [isHovered, setIsHovered] = useState(false);
    let [userMsg, setUserMsg] = useState([]);
    let [aiMsg, setAiMsg] = useState([]);
    let [qst, setQst] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [typingStatus, setTypingStatus] = useState();
    let [lengthOfMsg, setLengthOfMsg] = useState(0);
    const chatSideRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const username = process.env.REACT_APP_USERNAME_ESIM;
    const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();

    const handleUserMsg = async () => {
        setIsTyping(true);
        setLengthOfMsg(lengthOfMsg + 1);
        if (qst.length > 0) {
            setUserMsg(prevUserMsg => [...prevUserMsg, qst]);
            const token = encrypt({
                username,
                password,
                question: qst
            });
            const conf = {
                method: 'post',
                url: apiUrl + 'TrendAI',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    token: token,
                }
            };
            try {
                const response = await axios(conf);
                const encryptedResponse = await response.data
                const decryptedResponse = decrypt(encryptedResponse);
                console.log(decryptedResponse);
                setAiMsg(prevAiMsg => [...prevAiMsg, decryptedResponse.answer]);
            } catch (error) {
                setAiMsg(prevAiMsg => [...prevAiMsg, "Sorry, I am unable to answer your question at the moment. Please try again later."]);
                console.error('Error fetching AI response:', error.message);
            } finally {
                setIsTyping(false);
            }
        }
    };
    useEffect(() => {
        if (chatSideRef.current) {
            chatSideRef.current.scrollTo({
                top: chatSideRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [typingStatus]);

    return (
        <div className="term-condition">
            <Navigation1 />
            <main className="TrendAI_footer-frame-1">
                <div className="TrendAI_Img">
                    <Canvas className="canvas">
                        <ambientLight />
                        <pointLight position={[10, 10, 10]} />
                        <Suspense fallback={<Html><Loader /></Html>}>
                            <Model objUrl="esim3D.obj" mtlUrl="esim3D.mtl" />
                        </Suspense>
                        <OrbitControls />
                    </Canvas>
                </div>
                <div className="TrendAI_header-texts">
                    <h1 className="TrendAI_heading-2">Trend AI</h1>
                    <h4 className="TrendAI_heading-3">Our AI here to give you a hand about any of your questions</h4>
                </div>
                <section className="TrendAI_registration-texts" style={{
                    textAlign: "center",
                }}>
                    <div className="account-activation">
                        <div className="TrendAI_container">
                            <div className="TrendAI_chat-side" ref={chatSideRef} >
                                {Array.from({ length: lengthOfMsg }).map((_, index) => (
                                    <div key={index} className="TrendAI_chat-msg">
                                        <div className="TrendAI_user-msg">
                                            {userMsg[index]}
                                        </div>
                                        <div className="TrendAI_ai-msg">
                                            {(() => {
                                                if (isTyping && (lengthOfMsg - 1 === index)) {
                                                    return <TypeAnimation
                                                        key={`typing-${index}`}
                                                        sequence={[
                                                            ".",
                                                            300,
                                                            "..",
                                                            300,
                                                            "...",
                                                        ]}
                                                        speed={40}
                                                        cursor={true}
                                                        repeat={5}
                                                    />
                                                } else {
                                                    return <TypeAnimation
                                                        key={`not-typing-${index}`}
                                                        sequence={[
                                                            `${[aiMsg[index]]}`,
                                                            () => {
                                                                setTypingStatus(false);
                                                            }
                                                        ]}
                                                        speed={90}
                                                        cursor={true}
                                                        repeat={1}
                                                    />
                                                }

                                            })()}
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                            <div className="TrendAI_chat-input">
                                <input
                                    type="text"
                                    placeholder="Message Trend AI"
                                    value={qst}
                                    onChange={(e) => {
                                        setQst(e.target.value)
                                    }}
                                    onKeyDown={async (e) => {
                                        if (e.key === 'Enter') {
                                            setTypingStatus(true);
                                            await handleUserMsg();
                                            setQst("");
                                        } else {
                                            console.log("No message to send");
                                        }
                                    }}
                                />
                                <FaArrowCircleUp
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    style={{
                                        color: (isHovered && qst.length > 0) ? "9e2d5a" : "#ca3770",
                                        opacity: qst.length > 0 ? "1" : "0.4",
                                        fontSize: "1.8rem",
                                        cursor: qst.length > 0 ? "pointer" : "unset",
                                        alignSelf: "center",
                                        margin: "0 1rem"
                                    }}
                                    onClick={async () => {
                                        // console.log("clicked");
                                        if (qst.length > 0) {
                                            setTypingStatus(true);
                                            await handleUserMsg();
                                            setQst("");
                                        } else {
                                            // console.log("No message to send");
                                        }
                                    }}
                                />

                            </div>
                        </div>
                        <img
                            className="whatsapp-logo6d974d7esvg-icon"
                            loading="eager"
                            alt=""
                            src="/whatsapplogo6d974d7esvg1@2x.png"
                            onClick={() => {
                                window.open("https://api.whatsapp.com/send/?phone=447365833569")

                            }}
                        />
                    </div>
                </section>
            </main>
            <Divfooter1 />
            <div className="divfooter-sub3">
                <div className="qr-curves-b-s-v-g">
                    <Link style={{
                        textDecoration: "none",
                        color: "inherit"
                    }}
                        to="/privacy-policy"
                    > <b >Privacy Policy</b></Link>
                    <Link style={{
                        textDecoration: "none",
                        color: "inherit"
                    }}
                        to="/refund-policy"
                    > <b >Refund Policy</b></Link>
                    <Link style={{
                        textDecoration: "none",
                        color: "inherit"
                    }}
                        to="/term-condition"
                    > <b >Terms & Conditions</b></Link>

                    <div className="social-media">
                        <b >Contact Us</b>

                        <a href="https://www.facebook.com/people/Trendesim-English/61558743480892/" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquareFacebook />
                        </a>
                        <a href="https://www.instagram.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquareInstagram />
                        </a>
                        <a href="https://www.youtube.com/@TrendeSIM" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquareYoutube />
                        </a>
                        <a href="https://www.tiktok.com/@trendesim" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaTiktok />
                        </a>
                        <a href="https://www.pinterest.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquarePinterest />
                        </a>

                    </div>
                </div>
                <b>© 2024 Trend eSIM</b>
            </div>
        </div>
    )
}

export default TrendAi