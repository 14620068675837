import "./Term&Condition.css";
import Navigation1 from "../components/Navigation1";
import Divfooter1 from "../components/Divfooter1";
import {
    FaSquareFacebook,
    FaSquareInstagram,
    FaSquareYoutube,
    FaTiktok,
    FaSquarePinterest
} from "react-icons/fa6";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function PrivacyPolicy() {

    useEffect(() => {
        function handleScroll() {
            const element = document.querySelector(".whatsapp-logo6d974d7esvg-icon");
            const elementAI = document.querySelector(".TrendAI-Logo");
            const { scrollHeight, scrollTop, clientHeight } = document.documentElement;

            if (element && elementAI) {
                if (scrollHeight - scrollTop === clientHeight) {
                    // Scroll has reached the end
                    // console.log("Scroll reached the end");
                    element.style.bottom = "75%";
                    elementAI.style.bottom = "70%";
                } else {
                    element.style.bottom = "-1%";
                    elementAI.style.bottom = "15%";

                }
            }
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    return (
        <div className="term-condition">
            <Navigation1 />
            <main className="footer-frame-1">
                <div className="header-texts">
                    <h1 className="heading-2">Refund Policy</h1>
                </div>
                <section className="registration-texts" >
                    <div className="account-activation">
                        <div className="monty-uk-global-container-1">
                            <span className="monty-uk-global-container1">
                                <p className="monty-uk-global">
                                    At TrendeSIM, we're committed to ensuring that our customers get a seamless and enjoyable experience. Our refund policy is designed to offer clarity in the rare instances where you may need to request a refund. Below, you'll find detailed information on our refund procedures and conditions.                                </p>
                                {/* <p className="bridge-house181-queen">
                                    We are committed to keep your personal information secure and protected. A variety of industry-standard security technologies and procedures are used in order to protect your personal information from unauthorized access, usage or disclosure. However, no security measure is totally secure, and we cannot guarantee the security of your information.
                                </p> */}
                            </span>
                        </div>
                        <div className="please-take-some-container">
                            <h2
                                style={{
                                    // fontSize: "inherit",
                                    // lineHeight: "60px",
                                    fontWeight: "500",
                                    // fontFamily: "inherit",
                                    color: "#ca3770",
                                }}
                            >Refund Eligibility and Conditions</h2>
                            <p className="please-take-some">
                                {/* <br /> */}
                                <b>Coverage Issues:</b> If the eSIM cannot be installed due to coverage problems, you may request a refund or change.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Technical Issues:</b>  In cases where technical problems originate from eSimCard and cannot be resolved promptly, refunds are possible only if no data has been consumed.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Data Package Validity:</b>  No refunds are offered once the eSIM is installed and used.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Accidental Purchases:</b>  No refunds are offered once the eSIM is installed and used.
                            </p>
                            <p className="no-agency-partnership">
                                <b>ncorrect Charges:</b> I Disputes on charges must be notified within 12 days of receipt, containing details of why the amount is incorrect.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Fraudulent Activities:</b> TrendeSIM reserves the right to refuse refunds in cases of suspected abuse, term violation, or fraudulent activities.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Phone Compatibility:</b> It's crucial to ensure your phone is eSIM compatible before purchase. No refunds are issued for incompatibility issues.
                            </p>
                            <p className="no-agency-partnership">
                                <b>One-time Purchase:</b> eSIM is a one-time purchase and non-refundable
                            </p>
                            <p className="no-agency-partnership">
                                <b>Virtual Numbers:</b> Virtual Numbers will not work on WhatsApp and other social media services, and the customer is not entitled to a refund if he does not get the OTP codes for such services.
                            </p>
                        </div>
                        <div className="please-take-some-container">
                            <h2
                             style={{
                                // fontSize: "inherit",
                                // lineHeight: "60px",
                                fontWeight: "500",
                                // fontFamily: "inherit",
                                color: "#ca3770",
                            }}
                            >Process for Requesting a Refund</h2>
                            <p className="please-take-some">
                                {/* <br /> */}
                                <b>Time Frame:</b> Refund requests must be made within 30 days of purchase.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Cooperation:</b> Prompt/timely cooperation in troubleshooting is necessary for a refund consideration.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Notification:</b>  Email support@trendesim.com with your refund request, providing relevant details and reasons.
                            </p>

                        </div>
                        <div className="please-take-some-container">
                            <h2
                             style={{
                                // fontSize: "inherit",
                                // lineHeight: "60px",
                                fontWeight: "500",
                                // fontFamily: "inherit",
                                color: "#ca3770",
                            }}
                            >Exceptions and Specific Scenarios</h2>
                            <p className="please-take-some">
                                {/* <br /> */}
                                <b>Unauthorized Purchases:</b>  Subject to investigation and approval.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Modification:</b>  No modifications or customizations are allowed post-purchase.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Ultimate Mobile Services:</b> Service suspension may occur without notice and is not subject to refunds.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Note:</b> eSIMCard holds the right to cancel the purchase if they suspect any fraud or spam usage
                            </p>

                        </div>
                        <div className="please-take-some-container">
                            <h2
                             style={{
                                // fontSize: "inherit",
                                // lineHeight: "60px",
                                fontWeight: "500",
                                // fontFamily: "inherit",
                                color: "#ca3770",
                            }}
                            >Additional Terms</h2>
                            <p className="please-take-some">
                                {/* <br /> */}
                                <b>Fair Usage Policy:</b>  This applies to all bundles/plans.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Liability:</b> eSimCard is not liable for service unavailability or network issues.
                            </p>
                            <p className="no-agency-partnership">
                                <b>Warranty:</b> No guarantees on constant network availability.
                            </p>
                        </div>
                        <div className="please-take-some-container">
                            <h2
                             style={{
                                // fontSize: "inherit",
                                // lineHeight: "60px",
                                fontWeight: "500",
                                // fontFamily: "inherit",
                                color: "#ca3770",
                            }}
                            >Contact and Support</h2>
                            <p className="please-take-some">

                                For any queries or assistance, please contact our support team at support@trendesim.com. We're here to help you for a smooth and hassle-free experience.
                            </p>

                        </div>

                        <img
                            className="whatsapp-logo6d974d7esvg-icon"
                            loading="eager"
                            alt=""
                            src="/whatsapplogo6d974d7esvg1@2x.png"
                            onClick={() => {
                                window.open("https://api.whatsapp.com/send/?phone=447365833569")

                            }}
                        />
                        <Link to="/trend-AI">
                            <img
                                className="TrendAI-Logo"
                                alt=""
                                src="/chatbot.png"
                                title="Contact Our AI"
                            />
                        </Link>
                    </div>
                </section>
            </main>
            <Divfooter1 />
            <div className="divfooter-sub3">
                <div className="qr-curves-b-s-v-g">
                <Link style={{
                        textDecoration: "none",
                        color: "inherit"
                    }}
                        to="/privacy-policy"
                    > <b >Privacy Policy</b></Link>
                    <Link style={{
                        textDecoration: "none",
                        color: "inherit"
                    }}
                        to="/refund-policy"
                    > <b >Refund Policy</b></Link>
                    <Link style={{
                        textDecoration: "none",
                        color: "inherit"
                    }}
                        to="/term-condition"
                    > <b >Terms & Conditions</b></Link>

                    <div className="social-media">
                        <b >Contact Us</b>

                        <a href="https://www.facebook.com/people/Trendesim-English/61558743480892/" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquareFacebook />
                        </a>
                        <a href="https://www.instagram.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquareInstagram />
                        </a>
                        <a href="https://www.youtube.com/@TrendeSIM" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquareYoutube />
                        </a>
                        <a href="https://www.tiktok.com/@trendesim" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaTiktok />
                        </a>
                        <a href="https://www.pinterest.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquarePinterest />
                        </a>

                    </div>
                </div>
                <b>© 2024 Trend eSIM</b>
            </div>
        </div >
    )
}

export default PrivacyPolicy