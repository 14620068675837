import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate,
} from "react-router-dom";
import WwwmontyesimcomByHtmltod61 from "./pages/WwwmontyesimcomByHtmltod61";
import WwwmontyesimcomByHtmltod from "./pages/WwwmontyesimcomByHtmltod";
import WwwmontyesimcomByHtmltod5 from "./pages/WwwmontyesimcomByHtmltod5";
import WwwmontyesimcomByHtmltod1 from "./pages/WwwmontyesimcomByHtmltod1";
import WwwmontyesimcomByHtmltod2 from "./pages/WwwmontyesimcomByHtmltod2";
// import WwwmontyesimcomByHtmltod3 from "./pages/WwwmontyesimcomByHtmltod3";
import WwwmontyesimcomByHtmltod4 from "./pages/WwwmontyesimcomByHtmltod4";
import WwwmontyesimcomByHtmltod6 from "./pages/WwwmontyesimcomByHtmltod6";
import WwwmontyesimcomByHtmltod7 from "./pages/WwwmontyesimcomByHtmltod7";
import WwwmontyesimcomByHtmltod8 from "./pages/Term&Condition";
import WwwmontyesimcomByHtmltod9 from "./pages/WwwmontyesimcomByHtmltod9";
import WwwmontyesimcomByHtmltod10 from "./pages/Privacy&Policy";
import WwwmontyesimcomByHtmltod12 from "./pages/Refund&Policy";
import WwwmontyesimcomByHtmltod11 from "./pages/TrendAi";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Home";
        metaDescription = "home page";
        break;
      case "/how-it-works":
        title = "How it works";
        metaDescription = "how it works page";
        break;
      case "/partnership":
        title = "Partner with Us";
        metaDescription = "partnership page";
        break;
      case "/about-us":
        title = "About Us";
        metaDescription = "About us page";
        break;
      case "/contact-us":
        title = "Contact Us";
        metaDescription = "Contact us page";
        break;
      case "/sign-in":
        title = "Sign In";
        metaDescription = "Sign in page";
        break;
      case "/plans":
        title = "Shop Plans";
        metaDescription = "Shop plans page";
        break;
      case "/checkout":
        title = "Shop Plans";
        metaDescription = "Chekcout page";
        break;
      case "/term-condition":
        title = "Terms & Conditions";
        metaDescription = "Term & Condition page";
        break;
      case "/privacy-policy":
        title = "Privacy Policy";
        metaDescription = "Privacy Policy page";
        break;
      case "/refund-policy":
        title = "Refund Policy";
        metaDescription = "Refund Policy page";
        break;
      case "/payment-status":
        title = "Payment Status";
        metaDescription = "Payment Status page";
        break;
      case "/trend-AI":
        title = "Trend AI";
        metaDescription = "Trend AI page";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<WwwmontyesimcomByHtmltod61 />} />
      <Route
        path="/how-it-works"
        element={<WwwmontyesimcomByHtmltod />}
      />
      <Route
        path="/partnership"
        element={<WwwmontyesimcomByHtmltod5 />}
      />
      <Route
        path="/about-us"
        element={<WwwmontyesimcomByHtmltod1 />}
      />
      <Route
        path="/contact-us"
        element={<WwwmontyesimcomByHtmltod2 />}
      />
      {/* <Route
        path="/sign-in"
        element={<WwwmontyesimcomByHtmltod3 />}
      /> */}
      <Route
        path="/plans/*"
        element={<WwwmontyesimcomByHtmltod4 />}
      />
      <Route
        path="/plans/one-plan"
        element={<WwwmontyesimcomByHtmltod6 />}
      />
      <Route
        path="/checkout"
        element={<WwwmontyesimcomByHtmltod7 />}
      />
      <Route
        path="/payment-status"
        element={<WwwmontyesimcomByHtmltod9 />}
      />
      <Route
        path="/term-condition"
        element={<WwwmontyesimcomByHtmltod8 />}
      />
      <Route
        path="/privacy-policy"
        element={<WwwmontyesimcomByHtmltod10 />}
      />
      <Route
        path="/refund-policy"
        element={<WwwmontyesimcomByHtmltod12 />}
      />
      <Route
        path="/trend-AI"
        element={<WwwmontyesimcomByHtmltod11 />}
      />
      {/* Catch-all route for undefined paths */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
export default App;
