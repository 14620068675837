import "./Form.css";
import { Buffer } from "buffer";
import { encrypt } from '../crypto';
import { useState, useEffect } from "react";
import toast from 'react-hot-toast';
import axios from 'axios';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Countries from '../asset/countries.json';

const Form = () => {
  const animatedComponents = makeAnimated();
  const apiUrl = process.env.REACT_APP_API_URL;
  const username = process.env.REACT_APP_USERNAME_ESIM;
  const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [company, setCompany] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [full_name, setFull_name] = useState("");
  const countryOptions = Countries.map((country) => {
    return {
      value: country.name,
      label: country.name
    }
  });
  const SentMial = () => {
    // setIsLoading(true);
    if (email === "" || message === "" || !email.includes("@") || ![
      "mail.com", "gmail.com", "yahoo.com", "hotmail.com",
      "outlook.com", "aol.com", "icloud.com", "protonmail.com",
      "zoho.com", "yandex.com", "tutanota.com", "gmx.com",
      "yopmail.com", "mail.ru", "inbox.lv", "t-online.de",
      "web.de", "gmx.net", "mail.de", "email.com", "email.cz",
      "seznam.cz", "centrum.cz"].includes(email.split("@")[1])) {
      toast.error("Please fill all the required fields with valid values",
        {
          style: {

            fontFamily: 'sans-serif',
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#ca3770',
          },
        });

    } else {

      const token = encrypt({
        email,
        message,
        jobRole,
        company,
        country : country.value,
        phone,
        full_name,
        data_access: {
          username,
          password
        }
      });
      const conf = {
        method: 'post',
        url: apiUrl + 'CreateReseller',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          token: token,
        },

      }
      const sendMailPromise = axios(conf)
      toast.promise(
        sendMailPromise,
        {
          pending: 'Your request is sending ...',
          success: 'Your request sent successfully, can you check your email for more details',
          error: 'Error sending message'
        }
        ,
        {
          duration: 5000,
          style: {
            fontFamily: 'sans-serif',
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#ca3770',
          },
        }
      ).then(() => {

        setEmail("");
        setMessage("");
        setPhone("");
        setCountry("");
        setCompany("");
        setJobRole("");
        setFull_name("");
      });
    }
    
  }


  return (
    <section className="form">
      <div className="link-contactus">
        <div className="input-full-name">
          <div className="label-email2">
            <img
              className="contact-arrowa4cb5823svg-icon4"
              alt=""
              src="/contactarrowa4cb5823svg-11.svg"
            />
            <div className="input-job-role">
              <div className="get-in-touch">Get in touch</div>
            </div>
          </div>
        </div>
        <div className="heading-1-how-to-become-a-re-parent">
          <h1 className="heading-19">How to Become a Reseller?</h1>
          <div className="fill-out-the">
            Fill out the form below to contact us and start the partnership
            process.
          </div>
        </div>
      </div>
      <div className="frame-parent3">
        <div className="frame-parent4">
          <div className="frame-parent5">
            <div className="label-full-name-parent">
              <div className="label-full">Full Name</div>
              <input
                className="input2"
                placeholder="Please enter your full name"
                type="text"
                value={full_name}
                onChange={(e) => {
                  setFull_name(e.target.value);
                }}
              />
            </div>
            <div className="phone-input-label">
              <div className="label-phone">Phone Number</div>
              <div className="mobile-number-input-label">
                {/* <div className="divselect-wrapper">
                  <div className="arrow-down">+961</div>
                  <div className="input3">
                    <div className="div3">▼</div>
                  </div>
                </div> */}
                {/* <select id="PhoneSelect" class="phone-select">
                  <option value="+912">+912</option>
                  <option value="+212">+212</option>
                  <option value="+326">+326</option>
                  <option value="+695">+695</option>
                  <option value="+9458">+9458</option>
                </select> */}

                <input
                  className="input4"
                  placeholder="Mobile number"
                  type="text"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-section">
              <div className="label-country">Country</div>
              {/* <select id="countrySelect" class="country-select">
                <option value="">Select Your Country</option>
                <option value="morocco">Morocco</option>
                <option value="usa">USA</option>
                <option value="canada">Canada</option>
                <option value="uk">UK</option>
              </select> */}
              <Select
              placeholder="Select Your Country"
                closeMenuOnSelect={false}
                components={animatedComponents}
                // defaultValue={defaultValueOptions}
                // isMulti
                options={countryOptions}
                value={country}
                onChange={(e) => {
                  setCountry(e);
                }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    width: '100%',
                    height: '100%',
                    background: 'transparent',
                    borderRadius: '25px',
                    border: 'none',
                    outline: 'none',
                    boxShadow: state.isFocused ? 0 : 0, // this will remove the blue border
                    '&:hover': {
                      border: 'none', // this will remove the border on hover
                    }
                  }),
                }}
                className="country-select"
              />
            </div>
            <div className="label-message-container">
              <span>Message</span>
              <span className="span">*</span>
            </div>
          </div>
          <div className="footer-section1">
            <div className="colmd">
              <div className="label-email-container">
                <span className="label-email-container1">
                  <span>Email</span>
                  <span className="span1">*</span>
                </span>
              </div>
              <input className="input5" placeholder="Email" type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase());
              }} />
            </div>
            <div className="colmd1">
              <div className="label-company">Company</div>
              <input
                className="input6"
                placeholder="Please enter your company's details"
                type="text"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
              />
            </div>
            <div className="colmd2">
              <div className="label-job">Job Role</div>
              <input
                className="input7"
                placeholder="Please enter you job role"
                type="text"
                value={jobRole}
                onChange={(e) => {
                  setJobRole(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="label-email3">
          <textarea className="textarea1" placeholder="Message..."
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <div className="input8">
            <div className="required-fields">
              <span>*</span>
              <span className="required-fields1">Required Fields</span>
            </div>
          </div>
          <div className="button14">
            <button className="button15" onClick={(e) => {
              e.preventDefault();
              SentMial();
            }}>
              <div className="send-message1">Send Message</div>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form;
